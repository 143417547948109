//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  statusTextEnum,
  groupBuyClassEnum,
  groupTypeTextEnum,
  groupTypeBgStyle,
  offerTypeTextEnum,
  groupBuyPriceColor
} from "../constants";
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statusTextEnum,
      groupBuyClassEnum,
      groupTypeTextEnum,
      groupTypeBgStyle,
      offerTypeTextEnum,
      groupBuyPriceColor
    };
  },
  computed: {},
  filters: {
    attrlistFilter(list) {
      const attrs = list.filter(x => x.value)
      if(attrs.length) {
        let str = ''
        attrs.forEach(el => {
          str += `${el.name}:${el.value}`
        });
        return str
      }
      return '无'
    },
    offerTypeF(val) {
      switch(val) {
        case 1:
          return "会员报价"
        case 2:
          return "市场报价"
        case 3:
          return "成员报价"
        default:
          break
      }
    },
    joinTypeF(val) {
      switch(val) {
        case 1:
          return "会员加盟"
        case 2:
          return "市场加盟"
        case 3:
          return "成员加盟"
        default:
          break
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /**
     * 根据state返回不同样式
     */
    coustomState(state) {
      return [2, 3, 4].includes(state) ? "status-4" : "status";
    },
    /**
     * 根据state返回不同样式
     */
    coustomMsgState(state) {
      return [2, 3, 4].includes(state) ? "msg-state" : "msg";
    },
  },
};
