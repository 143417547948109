// 集采单状态 
export const statusTextEnum = {
    1:'集采中', 2:'集采完成', 3:'集采作废',4:'集采失败', 5:'加盟中', 6:'报价中'
}
// 集采参数展示枚举
export const groupBuyClassEnum = {
    1:'商品参数', 2:'机械参数', 3:'设备参数'
}
// 集采分类
export const groupBuyTypeEnum = [
    {name: '需方发起', value:1},
    {name: '供方发起', value:null},
    {name: '企业专项', value:2}
]
// 集采分类 
export const groupTypeTextEnum = {
    1:'即报即采', 2:'汇集采购', 3:'企业专项'
}
export const groupTypeBgStyle = {
    1:'bg-blue', 2:'bg-brown', 3:'bg-orange'
}
export const offerTypeTextEnum = {
    1:'会员报价', 2:'市场报价',
}
// 集采状态-价格预警
export const groupBuyPriceColor = {
    // 集采状态_价格预警
    1: "car-e1",
    2: "car-02",
    3: "car-6b",
  }