//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonTag from "../commonTag";
import comTabSke from "../skeComponents/comTabSke.vue";
import skeMeet from "./components/skeMeet.vue";
import ComInviteSke from "./components/ske.vue";
import env from "@/../env.js";
import {
  getIndustryCategoryTrees,
  getInvestmentDemandTypes,
  getInvestmentAdelevelTypes,
  getImPreferentialPolicyCategories,
  getIndustryCategoryList,
  getInvestmentDemandsList,
  getImIndustrialParksList,
  getImPreferentialPolicyList
} from "@/api/apiInvestment";
export default {
  data() {
    return {
      list: [],
      params: null,
      allTypeText: ["引资项目", "投资需求", "产业园区", "会议会展"],
      activeIndex: 0,
      menuList: [],
      firstType: "",
      load: false,
      isClose: false,
      isMore: false,
      isLoadData: false,
      isEmpty: false,
      limit: 6,
      currentPage: 1
    };
  },
  components: {
    CommonTag,
    TabList: () => import("@/components/TabList/index.vue"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    InviteProject: () => import("./components/inviteProject.vue"),
    InvestmentItem: () => import("./components/investmentItem.vue"),
    IndustrialItem: () => import("./components/industrialItem.vue"),
    MeetingItem: () => import("./components/meetingItem.vue"),
    comTabSke,
    ComInviteSke,
    NoData: () => import("@/pages/Home/components/noData.vue"),
    skeMeet
  },
  created() {
    this.getGoosTypeList();
    this.getGoodsList();
  },
  methods: {
    toDetail(item) {
      console.log(item.no);
      let { no, industryNo } = item;
      let url = env[process.env.environment].VUE_INVERTEMT;
      switch (this.activeIndex) {
        case 0:
          url += `/details?no=${no}&industryNo=${industryNo}`;
          break;
        case 1:
          url += `/detailsDemand?no=${no}`;
          break;
        case 2:
          url += `/detailsPark?no=${no}`;
          break;
        case 3:
          url += `/conferenceExhibitionDetail/${no}`;
          break;

        default:
          break;
      }
      window.open(url);
    },
    // 切换tab栏
    changeTag(index) {
      console.log("index", index);
      this.activeIndex = index;
      this.menuList = [];
      this.firstType = "";
      this.getGoosTypeList();
      this.refreshData();
    },
    async refreshData() {
      this.currentPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.list = [];
      this.getGoodsList();
    },
    async getGoodsList() {
      this.load = true;
      let res = {};
      let list = [];
      let total = 0;
      let params = {
        limit: this.limit,
        page: this.currentPage
      };
      switch (this.activeIndex) {
        case 0: // 引资项目
          {
            params.timeSort = 2;
            params.industryCategoryFirstNo = this.firstType;
            res = await getIndustryCategoryList(params);
            let { code, data } = res.data;
            if (code === 200) {
              list = data.records || [];
              total = data.total;
            }
          }
          break;
        case 1: // 投资需求
          {
            params.asc = false;
            params.demandTypeNo = this.firstType;
            params.orderByColumn = "iid.createTime";
            res = await getInvestmentDemandsList(params);
            let { code, data } = res.data;
            if (code === 200) {
              list = data.records || [];
              total = data.total;
            }
          }
          break;
        case 2: // 产业园区
          {
            params.sortCode = "PUSH_TIME-false";
            params.assistantSectionLevelNo = this.firstType;
            res = await getImIndustrialParksList(params);
            let { code, data } = res.data;
            if (code === 200) {
              list = data.records || [];
              total = data.total;
            }
          }
          break;
        case 3: // 会议会展
          {
            params.startTimeSort = 1;
            params.industrialTypeNo = this.firstType;
            res = await getImPreferentialPolicyList(params);
            let { code, data } = res.data;
            if (code === 200) {
              list = data.records || [];
              total = data.total;
            }
          }
          break;
        default:
          break;
      }

      this.isLoadData = list.length === params.limit;
      this.list = this.list.concat(list);
      this.isMore = this.currentPage < Math.ceil(total / this.limit);
      this.isEmpty = this.list.length === 0;
      this.load = false;
    },
    // 获取二级菜单
    async getGoosTypeList() {
      let list = [{ name: "全部", value: "" }];
      let res = {};
      switch (this.activeIndex) {
        case 0:
          {
            res = await getIndustryCategoryTrees();
            let { data, code } = res.data;
            if (code === 200) {
              for (let i = 0; i < data.length; i++) {
                let item = {
                  name: data[i]["categoryName"],
                  value: data[i]["categoryNo"]
                };
                list.push(item);
              }
            }
          }
          break;
        case 1:
          {
            res = await getInvestmentDemandTypes();
            let { data, code } = res.data;
            if (code === 200) {
              for (let i = 0; i < data.length; i++) {
                let item = {
                  name: data[i]["demandTypeName"],
                  value: data[i]["no"]
                };
                list.push(item);
              }
            }
          }
          break;
        case 2:
          {
            res = await getInvestmentAdelevelTypes();
            let { data, code } = res.data;
            if (code === 200) {
              for (let i = 0; i < data.length; i++) {
                let item = {
                  name: data[i]["levelName"],
                  value: data[i]["no"]
                };
                list.push(item);
              }
            }
          }
          break;
        case 3:
          {
            res = await getImPreferentialPolicyCategories();
            let { data, code } = res.data;
            if (code === 200) {
              for (let i = 0; i < data.records.length; i++) {
                let item = {
                  name: data.records[i]["name"],
                  value: data.records[i]["no"]
                };
                list.push(item);
              }
            }
          }
          break;

        default:
          break;
      }
      this.menuList = list;
    },
    clickTabItem(item) {
      this.firstType = item.value;
      this.currentPage = 1;
      // this.getGoodsList();
      this.refreshData();
    },
    loadMore() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.isClose = true;
        this.getGoodsList();
      }
    }
  }
};
