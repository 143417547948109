//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import { apiInquiryPrice } from "@/api/apiInquiryPrice";
import { apiResSearch } from "@/api/apiResSearch";
import env from "@/../env.js";
import cookie from "@/storage/cookies.js";
import { groupBuyTypeEnum } from "./constants";
import comTabSke from "../skeComponents/comTabSke";
import comTMBSke from "../skeComponents/comTMBSke";
import GroupBuyingCell from "@/pages/Home/components/goods/components/groupBuyingCell/indexCell.vue";
import newspaper from "./cellItem/newspaper";
import TabList from "@/components/TabList/index.vue";

export default {
  components: {
    CommonTag: () => import("../commonTag"), //切换tag
    NoData: () => import("../noData"),
    TabList,
    newspaper, // 集报集采cell
    GroupBuyingCell, // 供方发起
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comTabSke,
    comTMBSke
  },
  data: function() {
    return {
      tagList: ["需方发起", "供方发起", "企业专项"],
      activeIndex: 0,
      typeList: [],
      firstType: "",
      groupbuyMode: 1,
      currentPage: 1,
      list: [],
      load: false,
      isClose: false,
      isMore: false,
      isLoadData: false,
      isEmpty: false
    };
  },
  async asyncData({ params, error }) {},
  async fetch() {
    this.requestTabList();
    const parmas = {
      limit: 6,
      current: 1,
      groupbuyMode: 1,
      groupbuyClass: ""
    };
    const res = await apiHome.getJicaiList(parmas);
    const { data, msg, code } = res.data;
    if (+code === 200) {
      const records = data.records || [];
      const list =
        (records.length &&
          records.map(item => {
            return {
              ...item,
              details: JSON.parse(item.details)
            };
          })) ||
        [];
      this.isLoadData = records.length === parmas.limit;
      this.list = this.list.concat(list);
      this.isMore = this.currentPage < data.totalPage;
      this.load = false;
      this.isEmpty = this.list.length === 0;
      return;
    }
    this.load = false;
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 收起
    refreshData() {
      this.currentPage = 1;
      this.isClose = false;
      this.isMore = false;
      this.list = [];
      this.requestHttpList();
    },
    // 加载更多数据
    loadMore() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.isClose = true;
        this.requestHttpList();
      }
    },
    changeTag(index) {
      this.typeList = [];
      const text = this.tagList[index];
      this.isEmpty = false;
      this.activeIndex = index;
      this.firstType = "";
      this.groupbuyMode = groupBuyTypeEnum.find(x => x.name === text).value;
      this.requestTabList();
      this.refreshData();
    },
    // 请求列表接口
    requestHttpList() {
      const i = this.activeIndex;
      i !== 1 && this.getGoodsList();
      i === 1 && this.getGroupSaleList();
    },
    // 一级菜单接口
    async requestTabList() {
      const i = this.activeIndex;
      if ([0, 2].includes(i)) {
        new Promise(resolve => {
          return resolve([
            { name: "全部", value: "" },
            { name: "商品材料", value: 1 },
            { name: "机械租赁", value: 2 },
            { name: "设备租赁", value: 3 }
          ]);
        }).then(res => {
          this.typeList = res;
        });
        return;
      }
      const res = await apiInquiryPrice.getProducts();
      const { code, data } = res.data;
      if (+code === 200) {
        const list = data.map(item => {
          return {
            value: item.id,
            name: item.name
          };
        });
        this.typeList = [{ name: "全部", value: "" }, ...list];
      }
    },
    // 选中子集分类
    checkChildType(item) {
      const { value } = item;
      this.firstType = value;
      this.refreshData();
    },
    // 获取供方列表
    async getGroupSaleList() {
      this.load = true;
      const parmas = {
        provinceNo: "",
        cityNo: "",
        status: "",
        firstType: this.firstType,
        searchKey: "",
        current: this.currentPage,
        limit: 6
      };
      const res = await apiResSearch.getGroupSaleList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        let list = data.records || [];
        list =
          data.records &&
          list.map(item => ({
            ...item,
            detailList: JSON.parse(item.details)
          }));
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.totalPage;
        this.isEmpty = this.list.length === 0;
        this.load = false;
        this.isEmpty = this.list.length === 0;
        return;
      }
      this.load = false;
    },
    // 获取需方列表
    async getGoodsList() {
      this.load = true;
      const parmas = {
        limit: 6,
        current: this.currentPage,
        groupbuyClass: this.firstType,
        status: this.activeIndex === 2 ? 1 : "",
        groupbuyMode: this.groupbuyMode
      };
      const res = await apiHome.getJicaiList(parmas);
      const { data, msg, code } = res.data;
      if (+code === 200) {
        const records = data.records || [];
        const list =
          (records.length &&
            records.map(item => {
              return {
                ...item,
                details: JSON.parse(item.details)
              };
            })) ||
          [];
        console.log(list, "list");
        this.isLoadData = records.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isMore = this.currentPage < data.totalPage;
        this.load = false;
        this.isEmpty = this.list.length === 0;
        return;
      }
      this.load = false;
    },
    // 汇集采购/即报即采得详情页
    detail(id) {
      let ip =
        this.activeIndex === 1
          ? `${
              env[process.env.environment].VUE_APP_GROUNPBUYING
            }/collectDetails?id=${id}&groupbuyClass=${this.firstType}`
          : `${
              env[process.env.environment].VUE_APP_GROUNPBUYING
            }/fastDetails?id=${id}&groupbuyClass=${this.firstType}`;
      window.open(ip);
    }
  }
};
